import { FC } from "react";

import styles from "./Highlight.module.css";

type Props = {
  children?: React.ReactNode;
  variant?: "primary" | "secondary";
  style?: React.CSSProperties;
};

const Highlight: FC<Props> = ({ children, style = {}, variant = "primary" }) => {
  return (
    <div className={`${styles.Highlight} ${styles[variant]}`} style={style}>
      <div className={styles.Content}>{children}</div>
    </div>
  );
};

export default Highlight;
