import { FC } from "react";

import { Icon } from "../../types/Icons";

import styles from "./IconButton.module.css";

type Props = {
  icon: Icon;
  variant?: "primary" | "secondary";
  onClick?: () => void;
};

const IconButton: FC<Props> = ({ icon, variant = "primary", onClick }) => {
  return (
    <div className={`${styles.IconButton} ${styles[variant]}`} onClick={onClick}>
      <img src={`/icons/${icon}.svg`} alt={icon} className={styles.Icon} />
    </div>
  );
};

export default IconButton;
