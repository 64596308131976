export enum Icon {
  WHATSAPP = "whatsapp",
  EMAIL = "email",
  SEARCH = "search",
}

export enum SocialMedia {
  INSTAGRAM = "/icons/social-instagram.svg",
  FACEBOOK = "/icons/social-facebook.svg",
  TIKTOK = "/icons/social-tiktok.svg",
  EMAIL = "/icons/social-email.svg",
}
