import { useState, ReactNode } from "react";
import Modal from "../components/Modal";

type ModalHook = {
  Modal: JSX.Element;
  showModal: (title: string, content: ReactNode) => void;
  hideModal: () => void;
};

const useModal = (): ModalHook => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [modalTitle, setModalTitle] = useState<string>("");

  const showModal = (title: string, content: ReactNode) => {
    setModalTitle(title);
    setModalContent(content);
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
    setModalContent(null);
    setModalTitle("");
  };

  const ModalElement = (
    <Modal title={modalTitle} isOpen={isOpen} onClose={hideModal}>
      {modalContent}
    </Modal>
  );

  return {
    Modal: ModalElement,
    showModal,
    hideModal,
  };
};

export default useModal;
