import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "./App.css";
import { Icon } from "./types/Icons";

import useModal from "./hooks/useModal";

import Header from "./components/Header";
import QuickActions from "./components/QuickActions";
import IconButton from "./components/IconButton";
import Footer from "./components/Footer";
import Privacy from "./components/Policies/Privacy";
import Cookies from "./components/Policies/Cookies";

import Portfolio from "./sections/Portfolio";
import Highlight from "./sections/Highlight";
import Services from "./sections/Services";
import AboutUs from "./sections/AboutUs";

function App() {
  const { t } = useTranslation();
  const { Modal, showModal } = useModal();
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const setIsVideoVisible = useState(false)[1];

  const handleEmailClick = () => {
    // Opens user email client
    window.location.href = "mailto:comercial@atelierbossa.com";
  };

  const handleWhatsappClick = () => {
    // Opens whatsapp in a new tab
    window.open("https://api.whatsapp.com/send?phone=351924717834", "_blank");
  };

  const handlePrivacyClick = () => {
    showModal(t("footer.privacy.title"), <Privacy />);
  };

  const handleCookiesClick = () => {
    showModal(t("footer.cookies.title"), <Cookies />);
  };

  useEffect(() => {
    const video = videoRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVideoVisible(entry.isIntersecting);

        if (entry.isIntersecting && document.visibilityState === "visible") {
          setIsMuted((prevState) => {
            video.muted = prevState; // Unmute when visible
            return prevState;
          });
        } else {
          video.muted = true; // Mute otherwise
        }
      },
      { threshold: 0.5 }
    );

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        video.muted = true;
      } else {
        setIsMuted((isMutedState) => {
          setIsVideoVisible((isVisibleState) => {
            video.muted = isMutedState || !isVisibleState;
            return isVisibleState;
          });
          return isMutedState;
        });
      }
    };

    if (video) observer.observe(video);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (video) observer.unobserve(video);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [setIsMuted, setIsVideoVisible]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }
  }, [setIsMuted]);

  return (
    <div className="App">
      <Header />
      <video
        src="https://public-ab-videos.s3.eu-north-1.amazonaws.com/cover-compressed.mp4"
        ref={videoRef}
        data-section="institutional-video"
        autoPlay
        loop
        muted={isMuted}
        playsInline
        className="banner"
        preload="auto"
        poster="/cover-poster.jpeg"
        onClick={() => {
          setIsMuted((prevState) => !prevState);
        }}
      />
      <QuickActions>
        <IconButton icon={Icon.EMAIL} onClick={handleEmailClick} />
        <IconButton icon={Icon.WHATSAPP} variant="secondary" onClick={handleWhatsappClick} />
      </QuickActions>
      <Portfolio />
      <Highlight>{t("highlight.intro")}</Highlight>
      {/* Services */}
      <Services />
      <AboutUs />
      <Highlight variant="secondary" style={{ padding: "100px 30px" }}>
        {t("highlight.final")}
      </Highlight>
      {/* Footer */}
      <Footer onCookiesClick={handleCookiesClick} onPrivacyClick={handlePrivacyClick} />
      {Modal}
    </div>
  );
}

function AppWrapper() {
  return <App />;
}

export default AppWrapper;
