import { FC, useState } from "react";

import styles from "./QuickActions.module.css";

type QuickActionsProps = {
  children?: React.ReactNode;
};

const QuickActions: FC<QuickActionsProps> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return isCollapsed ? (
    <div className={styles.CollapsedQuickActions} onClick={() => setIsCollapsed(false)}></div>
  ) : (
    <div className={styles.QuickActions}>{children}</div>
  );
};

export default QuickActions;
