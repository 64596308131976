import { FC } from "react";

import styles from "./Services.module.css";
import { Trans, useTranslation } from "react-i18next";
import SectionWrapper from "../SectionWrapper";

type Props = {};

const Services: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper className={styles.Services} id="services" data-section="services">
      <div className={styles.Consulting}>
        <img src="/portfolio/10.jpeg" alt="Services" style={{ objectFit: "cover" }} width={"40%"} />
        <div className={styles.Content}>
          <h1>{t("services.readyToMove.title")}</h1>
          <Trans i18nKey="services.readyToMove.description" />
        </div>
        <div className={styles.HighlightRight}>{t("services.readyToMove.highlight")}</div>
      </div>

      <div className={styles.Styling}>
        <img src="/portfolio/16.jpeg" alt="Services" />
        <div className={styles.Content}>
          <div>
            <h1>{t("services.interiorDesign.title")}</h1>
            <p>{t("services.interiorDesign.description")}</p>
          </div>
        </div>
      </div>

      <div className={styles.Ready}>
        <img src="/portfolio/13.jpeg" alt="Ready to move" width={"40%"} />
        <div className={styles.Content}>
          <h1>{t("services.styling.title")}</h1>
          <p>{t("services.styling.description")}</p>
        </div>
        <div className={styles.Highlight}>{t("services.styling.highlight")}</div>
      </div>

      <div className={styles.InteriorDesign}>
        <img src="/portfolio/19.jpeg" alt="Services" />
        <div className={styles.Content}>
          <div>
            <h1>{t("services.consulting.title")}</h1>
            <Trans i18nKey="services.consulting.description" />
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Services;
