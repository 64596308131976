import React from "react";
import { useTranslation } from "react-i18next";

import Search from "../Search";
import MenuItem from "../Menu/MenuItem";

import styles from "./styles.module.css";
import LanguageSelector from "../LanguageSelector";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const MenuMobile: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return isOpen ? (
    <div className={styles.MenuMobile}>
      <div className={styles.Header}>
        <Search />
        <img src="/icons/close.svg" alt="Close" onClick={onClose} />
      </div>
      <div className={styles.MenuItems}>
        <MenuItem label={t("menu.projects")} href="#projects" toggleMenu={onClose} />
        <MenuItem label={t("menu.services")} href="#services" toggleMenu={onClose} />
        <MenuItem label={t("menu.about")} href="#about" toggleMenu={onClose} />
        <MenuItem label={t("menu.contact")} href="#contact" toggleMenu={onClose} />
        {/* <MenuItem label={t("menu.blog")} href="#blog" /> */}
        <LanguageSelector className={styles.LanguageSelector} onClick={onClose} />
      </div>
    </div>
  ) : null;
};

export default MenuMobile;
