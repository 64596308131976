import { FC, useCallback, useEffect, useRef, useState } from "react";

import styles from "./Carousel.module.css";

type Props = {
  media: string[];
};

const TIME_FOR_NEXT_IMAGE = 3000;

const Carousel: FC<Props> = ({ media }) => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout>();

  const formatIndex = (index: number) => {
    if (index < 10) {
      return "0" + index;
    } else {
      return index;
    }
  };

  const next = useCallback(() => {
    setVisibleIndex((prevState) => {
      if (prevState === media.length - 1) return 0;
      return prevState + 1;
    });
  }, [media]);

  const startTimer = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(next, TIME_FOR_NEXT_IMAGE);
  }, [next]);

  const previous = () => {
    setVisibleIndex((prevState) => {
      if (prevState === 0) return media.length - 1;
      return prevState - 1;
    });

    startTimer();
  };

  useEffect(() => {
    startTimer();
    return () => clearInterval(intervalRef.current);
  }, [startTimer]);

  return (
    <div className={styles.Carousel}>
      <div className={styles.Media}>
        <div className={styles.Track} style={{ transform: `translateX(-${visibleIndex * 100}%)` }}>
          {media.map((media, index) => (
            <img key={index} src={media} alt="portfolio" />
          ))}
        </div>
        <div className={styles.Navigation}>
          <div className={styles.Arrow} onClick={previous}>
            <img src="/icons/arrow-left.svg" alt="arrowLeft" />
          </div>
          <div
            className={styles.Arrow}
            onClick={() => {
              next();
              startTimer();
            }}
          >
            <img src="/icons/arrow-right.svg" alt="arrowRight" />
          </div>
        </div>
      </div>
      <div className={styles.Counter}>
        <span>{formatIndex(visibleIndex + 1)}</span>
        <span>/</span>
        <span>{formatIndex(media.length)}</span>
      </div>
    </div>
  );
};

export default Carousel;
