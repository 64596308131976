import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./AboutUs.module.css";

type Props = {};

const AboutUs: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.AboutUs} id="about" data-section="about">
      <div className={styles.Backdrop}>
        <span className={styles.mobileTitle}>
          <span>{t("aboutUs.title")}</span>
          <img src="/about-us/logo.png" alt="Logo" />
        </span>
      </div>
      <div className={styles.Content}>
        <div className={styles.presenting}>
          <span className={styles.luana}>
            <img src="/about-us/luana.jpeg" alt="Luana Gondim" id="luana" />
            <span className={styles.title}>Luana Gondim</span>
            <span className={styles.subTitle}>{t("aboutUs.luana.subTitle")}</span>
            <p>{t("aboutUs.luana.text")}</p>
          </span>
          <span className={styles.paola}>
            <img src="/about-us/paola.jpeg" alt="Paola Alterio" />
            <span className={styles.title}>Paola Alterio</span>
            <span className={styles.subTitle}>{t("aboutUs.paola.subTitle")}</span>
            <p>{t("aboutUs.paola.text")}</p>
          </span>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
