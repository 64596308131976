import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Policies.module.css";

const Cookies: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Content}>
      <p>{t("footer.cookies.text.description")}</p>
      <h2>{t("footer.cookies.text.h1")}</h2>
      <p>{t("footer.cookies.text.p1")}</p>
      <h2>{t("footer.cookies.text.h2")}</h2>
      <ul>
        <li>{t("footer.cookies.text.p2.bullets.1")}</li>
        <li>{t("footer.cookies.text.p2.bullets.2")}</li>
        <li>{t("footer.cookies.text.p2.bullets.3")}</li>
      </ul>
      <h2>{t("footer.cookies.text.h3")}</h2>
      <p>{t("footer.cookies.text.p3")}</p>
      <h2>{t("footer.cookies.text.h4")}</h2>
      <p>{t("footer.cookies.text.p4.1")}</p>
      <p>{t("footer.cookies.text.p4.2")}</p>
    </div>
  );
};

export default Cookies;
