import { FC, ChangeEvent, useState, KeyboardEvent } from "react";

import styles from "./styles.module.css";

const Search: FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [text, setText] = useState("");

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const onClickIcon = () => {
    // setIsCollapsed((prevState) => !prevState);
  };

  const onSearchKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") setIsCollapsed(true);
  };

  return isCollapsed ? (
    <div className={styles.SearchIcon} onClick={onClickIcon}>
      <img src="/icons/search.svg" alt="search" className={styles.Icon} width={31} height={31} />
    </div>
  ) : (
    <div>
      <input type="text" value={text} onChange={onChangeText} onKeyDown={onSearchKeyDown}></input>
    </div>
  );
};

export default Search;
