import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Header.module.css";
import MenuItem from "./Menu/MenuItem";
import Search from "./Search";
import LanguageSelector from "./LanguageSelector";
import MenuMobile from "./MenuMobile/MenuMobile";

const Header: FC = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const sections = document.querySelectorAll("[data-section]");

    const handleScroll = () => {
      let foundSection = "";

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const isVisible =
          rect.top < window.innerHeight * 0.5 && rect.bottom > window.innerHeight * 0.2;

        if (isVisible && !foundSection) {
          foundSection = section.id;
        }
      });

      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 10;

      if (scrolledToBottom)
        setActiveSection((prevState) => (prevState !== "contact" ? "contact" : prevState));
      else setActiveSection(foundSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isMenuOpen ? (
    <MenuMobile isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
  ) : (
    <div className={styles.Header}>
      <img
        src="/logo.svg"
        alt="Atelier Bossa"
        height={34}
        width={313}
        className={styles.Logo}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
      <span className={styles.Desktop}>
        <MenuItem
          label={t("menu.projects")}
          href="#projects"
          isActive={activeSection === "projects"}
        />
        <MenuItem
          label={t("menu.services")}
          href="#services"
          isActive={activeSection === "services"}
        />
        <MenuItem label={t("menu.about")} href="#about" isActive={activeSection === "about"} />
        <MenuItem
          label={t("menu.contact")}
          href="#contact"
          isActive={activeSection === "contact"}
        />
        {/* <MenuItem label={t("menu.blog")} href="#blog" /> */}
        <Search />
        <LanguageSelector />
      </span>
      <span className={styles.Mobile}>
        <img src="/icons/menu.svg" alt="menu" onClick={() => setIsMenuOpen(true)} />
      </span>
    </div>
  );
};

export default Header;
