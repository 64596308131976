import { FC } from "react";

import styles from "./SectionWrapper.module.css";

type Props = {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  "data-section": string;
};

const SectionWrapper: FC<Props> = ({ children, className, ...props }) => {
  return (
    <div className={`${styles.SectionWrapper} ${className}`} {...props}>
      {children}
    </div>
  );
};

export default SectionWrapper;
