import { FC } from "react";

import styles from "./Portfolio.module.css";
import { Trans, useTranslation } from "react-i18next";

import SectionWrapper from "../SectionWrapper";

import Carousel from "../../components/Carousel";
import Button from "../../components/Button";

type Props = {};

const MEDIA = [
  "/portfolio/01.jpeg",
  "/portfolio/02.jpeg",
  "/portfolio/03.jpeg",
  "/portfolio/04.jpeg",
  "/portfolio/05.jpeg",
  "/portfolio/06.jpeg",
  "/portfolio/07.jpeg",
  "/portfolio/08.jpeg",
  "/portfolio/09.jpeg",
  "/portfolio/10.jpeg",
  "/portfolio/11.jpeg",
  "/portfolio/12.jpeg",
  "/portfolio/14.jpeg",
  "/portfolio/15.jpeg",
  "/portfolio/16.jpeg",
  "/portfolio/17.jpeg",
  "/portfolio/18.jpeg",
];

const Portfolio: FC<Props> = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open("https://instagram.com/atelierbossa", "_blank");
  };

  return (
    <SectionWrapper className={styles.Portfolio} id="projects" data-section="projects">
      <h1>
        <Trans i18nKey="portfolio.title" />
      </h1>
      <div className={styles.CarouselContainer}>
        <Carousel media={MEDIA} />
        <Button onClick={handleClick} className={styles.Button}>
          {t("portfolio.button")}
        </Button>
      </div>
    </SectionWrapper>
  );
};

export default Portfolio;
