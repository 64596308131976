import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.css";

type Props = { className?: string; onClick?: () => void };

const LanguageSelector: FC<Props> = ({ className, onClick }) => {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    onClick && onClick();
    i18n.changeLanguage(language);
  };

  return (
    <div className={`${styles.LanguageSelector} ${className}`}>
      <span onClick={() => changeLanguage("pt")}>PT</span> /
      <span onClick={() => changeLanguage("en")}>EN</span>
    </div>
  );
};

export default LanguageSelector;
