import { FC } from "react";
import { useTranslation } from "react-i18next";

import { SocialMedia } from "../../types/Icons";

import styles from "./Footer.module.css";

type Props = {
  onPrivacyClick: () => void;
  onCookiesClick: () => void;
};

const Footer: FC<Props> = ({ onCookiesClick, onPrivacyClick }) => {
  const { t } = useTranslation();

  const handleClickInstagram = () => {
    window.open("https://instagram.com/atelierbossa", "_blank");
  };

  const handleClickEmail = () => {
    window.location.href = "mailto:comercial@atelierbossa.com";
  };

  const handleClickTiktok = () => {
    window.open("https://www.tiktok.com/@atelierbossa", "_blank");
  };

  const handleClickFacebook = () => {
    window.open("https://www.facebook.com/atelierbossa", "_blank");
  };

  return (
    <footer className={styles.Footer} id="contact" data-section="contact">
      <div className={styles.LeftGroup}>
        <span>comercial@atelierbossa.com</span>
        <span>Av. Infante Santo 25 D, Lisboa</span>
        <span>+351 924 717 834</span>
      </div>
      <div className={styles.RightGroup}>
        <span className={styles.SocialMedia} style={{ display: "flex" }}>
          <img src={SocialMedia.INSTAGRAM} alt="instagram" onClick={handleClickInstagram} />
          <img src={SocialMedia.FACEBOOK} alt="facebook" onClick={handleClickFacebook} />
          <img src={SocialMedia.TIKTOK} alt="tiktok" onClick={handleClickTiktok} />
          <img src={SocialMedia.EMAIL} alt="email" onClick={handleClickEmail} />
        </span>
        <span className={styles.Links} style={{ display: "flex" }}>
          <span onClick={onPrivacyClick}>{t("footer.privacy.title")}</span>
          <span onClick={onCookiesClick}>{t("footer.cookies.title")}</span>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
