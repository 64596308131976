import { FC } from "react";
import styles from "./styles.module.css";

type MenuItemProps = {
  label: string;
  href: string;
  toggleMenu?: () => void;
  isActive?: boolean;
};

const MenuItem: FC<MenuItemProps> = ({ label, href, toggleMenu, isActive }) => {
  const handleClick = () => {
    toggleMenu && toggleMenu();
    window.location.href = href;
  };
  return (
    <div className={`${styles.MenuItem} ${isActive && styles.Active}`} onClick={handleClick}>
      {label}
    </div>
  );
};

export default MenuItem;
