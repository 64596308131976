import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Policies.module.css";

const Privacy: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Content}>
      <p>{t("footer.privacy.text.description")}</p>
      <h2>{t("footer.privacy.text.h1")}</h2>
      <p>{t("footer.privacy.text.p1")}</p>
      <h2>{t("footer.privacy.text.h2")}</h2>
      <p>{t("footer.privacy.text.p2.1")}</p>
      <ul>
        <li>{t("footer.privacy.text.p2.bullets.1")}</li>
        <li>{t("footer.privacy.text.p2.bullets.2")}</li>
        <li>{t("footer.privacy.text.p2.bullets.3")}</li>
      </ul>
      <p>{t("footer.privacy.text.p2.2")}</p>
      <h2>{t("footer.privacy.text.h3")}</h2>
      <p>{t("footer.privacy.text.p3")}</p>
      <h2>{t("footer.privacy.text.h4")}</h2>
      <p>{t("footer.privacy.text.p4")}</p>
      <h2>{t("footer.privacy.text.h5")}</h2>
      <p>{t("footer.privacy.text.p5.1")}</p>
      <p>{t("footer.privacy.text.p5.2")}</p>
    </div>
  );
};

export default Privacy;
