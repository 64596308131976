// src/components/Modal.js
import React, { FC } from "react";
import styles from "./Modal.module.css";

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const Modal: FC<Props> = ({ title, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.Overlay} onClick={onClose}>
      <div className={styles.Content} onClick={(e) => e.stopPropagation()}>
        <div className={styles.Header}>
          <h2>{title}</h2>
          <button onClick={onClose} className={styles.CloseButton}>
            ×
          </button>
        </div>
        <div className={styles.Body}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
