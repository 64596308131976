import { FC, ReactNode } from "react";

import styles from "./Button.module.css";

type Props = {
  children?: ReactNode;
  variant?: "primary" | "secondary";
  className?: string;
  onClick?: () => void;
};

const Button: FC<Props> = ({ children, variant = "primary", onClick, className }) => {
  return (
    <button className={`${styles.Button} ${styles[variant]} ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
